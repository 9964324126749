import React, { useState } from "react";
import { ProjectRecord } from "@/types";
import { useForm } from "react-hook-form";
import { addDueDateProjectReq } from "@/api/projectsApi";
import Modal from "@/components/Modal";
import FormInput from "@/components/FormInput";
import { Box, Button } from "@mui/material";
import FormDatePicker from "@/components/FormDatePicker";
import { useErrorBoundary } from "@/libs/ErrorBoundary.tsx";

const DueDateModal = ({
  projectId,
  onClose,
  updateIncentiveStatus,
}: {
  projectId: ProjectRecord["id"];
  getProjectsReq: () => void;
  onClose: () => void;
  updateIncentiveStatus: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleError = useErrorBoundary();

  const { handleSubmit, reset, control, watch } = useForm<{
    extendToDate: Date;
    note: string;
  }>({
    mode: "onChange",
  });

  const note = watch("note");
  const extendToDate = watch("extendToDate");

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      await addDueDateProjectReq(projectId, { extendToDate, note });
      await updateIncentiveStatus();
      handleClose();
    } catch (e: any) {
      handleError(e);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      open={!!projectId}
      onClose={handleClose}
      title={`Set a project extension due date`}
    >
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          marginTop: "24px",
          gap: "24px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormDatePicker
          name={"extendToDate"}
          control={control}
          label={"Extend To Date"}
          disablePast
          controlProps={{
            rules: { required: "Required" },
          }}
        />
        <FormInput
          name={"note"}
          control={control}
          label={"Note for Contractor"}
          placeholder={"Note"}
          multiline
          rows={4}
          controlProps={{
            rules: { required: "Required" },
          }}
        />
        <Box
          sx={{
            marginTop: "32px",
            display: "flex",
            gap: "8px",
            width: "100%",
          }}
        >
          <Button
            variant={"contained"}
            sx={{ width: "100%" }}
            disabled={!note || !extendToDate || isLoading}
            type={"submit"}
          >
            Save
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default DueDateModal;
